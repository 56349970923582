import Link from 'next/link';
import { isProduction } from '@/config';
import { MetaTag } from 'next-seo/lib/types';
import { NextSeo } from 'next-seo';
import { seoConfig } from '@/config/seo.config';
import { Button } from '@mui/material';
 // Import NextSeo component

const Custom404: React.FC = () => {
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      <NextSeo
        title="404 - Page Not Found"
        titleTemplate={`%s | ${seoConfig.title}`}
        defaultTitle={seoConfig.defaultTitle}
        description={`Sorry, the page you're looking for doesn't exist on maaboom.`}
        canonical={currentUrl}
        openGraph={{
            ...seoConfig.openGraph,
            url: currentUrl,
            title: `404 - Page Not Found | ${seoConfig.title}`,
            description: `Sorry, the page you're looking for doesn't exist on maaboom.`
          }}
        additionalMetaTags={[
            ...seoConfig.additionalMetaTags,
            isProduction ? seoConfig.robots.isProduction : seoConfig.robots.isNotProduction,
        ] as MetaTag[]}
        twitter={seoConfig.twitter}
        facebook={seoConfig.facebook}
      />

      <div style={{ textAlign: "center", padding: "50px" }}>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you&apos;re looking for doesn&apos;t exist.</p>
        <Link href="/" passHref>
          <Button variant="contained" color="primary" sx={{ mr: 1 }}>
            Go Back Home
          </Button>
        </Link>
      </div>
    </>
  );
};

export default Custom404;
